
const UserGroup = {
    GUEST: 0,
    USER: 1,
    SUPERUSER: 2,
    DEVELOPER: 5,
    PLATFORM_ADMIN: 6,
    MODERATOR: 7,
    ADMIN: 9,
    toString(userGroup) {
        switch (userGroup) {
            case UserGroup.GUEST: return "Гость";
            case UserGroup.USER: return "Пользователь";
            case UserGroup.SUPERUSER: return "Пользователь"; // Руководитель
            case UserGroup.DEVELOPER: return "Разработчик";
            case UserGroup.PLATFORM_ADMIN: return "Администратор платформы";
            case UserGroup.MODERATOR: return "Модератор";
            case UserGroup.ADMIN: return "Администратор";
            default: return "Неизвестный";
        }
    },
    hasPermission(userGroup, resourceAccessLevel) {
        return userGroup >= resourceAccessLevel;
    },
    notInGroups(userGroup, groups) {
        return !groups.some(g => g === userGroup);
    }
};

const EventNames = {
    UPDATE_DATA: 'e-update-data',
    UPDATE_WHITEPAGE_GENERATING_DONE: 'e-wp-gen-done'
};

const PUSH_SOUNDS = [
    {id: 'default', name: 'Обычный'},
    {id: 'ching', name: 'Дзынь'},
    {id: 'coin', name: 'Монетка'},
    {id: 'many_coins', name: 'Много монет'},
];

const FBCabState = {
    ACTIVE: 1,
    TO_BE_ADDED: 2,
    TO_BE_REMOVED: 3,
    FAILED_TO_ADD: 12,
    FAILED_TO_REMOVE: 13
};

const OrganicsState = {
    DISABLED: 0,
    ACTIVE: 1,
    toString(organicsState) {
        switch (organicsState) {
            case OrganicsState.DISABLED: return "DISABLED";
            case OrganicsState.ACTIVE: return "ACTIVE";
            default: return "Unknown";
        }
    }
};

const GameReleaseState = {
    NOT_RELEASED: 0,
    RELEASED: 1,
    BANNED_BY_FB: 2,
    BANNED_ON_MARKET: 3,
    RESTRICTED_99_CABS: 4,
    WAIT_FOR_RELEASE: 5,
    IN_DEVELOPMENT: 6,
    getColorClass(releaseState, defaultColor = "", simplified = false) {
        switch (releaseState) {
            case GameReleaseState.BANNED_BY_FB: return "gray";
            case GameReleaseState.BANNED_ON_MARKET: return "red";
            case GameReleaseState.RESTRICTED_99_CABS: return simplified ? defaultColor : "yellow";
            default: return defaultColor;
        }
    }
};

const NodeIPState = {
    NEW: 0,
    ACTIVE: 1,
    INACTIVE: 2,
    toString(trafficState) {
        switch (trafficState) {
            case NodeIPState.NEW: return "NEW";
            case NodeIPState.ACTIVE: return "ACTIVE";
            case NodeIPState.INACTIVE: return "INACTIVE";
            default: return "Unknown";
        }
    }
};

const Token = {
    USDT_TRC20: 'USDT_TRC20',
    toString(token) {
        switch (token) {
            case Token.USDT_TRC20: return "USDT (TRC20)";
            default: return "Unknown";
        }
    },
    getDepositTypeID(token) {
        switch (token) {
            case Token.USDT_TRC20: return 6;
            default: return 0;
        }
    }
};

const DepositRequestStatus = {
    PENDING: 0,
    COMPLETED: 1,
    EXPIRED: 2,
    PROCESSING: 3,
    toString(status) {
        switch (status) {
            case DepositRequestStatus.PENDING: return "PENDING";
            case DepositRequestStatus.COMPLETED: return "COMPLETED";
            case DepositRequestStatus.EXPIRED: return "EXPIRED";
            case DepositRequestStatus.PROCESSING: return "PROCESSING";
            default: return "Unknown";
        }
    }
};

const APILevel = {
    API_SUPERUSER: 2,
    API_DEVELOPER: 5,
    API_ADMIN: 9,
    toString(status) {
        switch (status) {
            case APILevel.SUPERUSER: return "API_SUPERUSER";
            case APILevel.DEVELOPER: return "API_DEVELOPER";
            case APILevel.ADMIN: return "API_ADMIN";
            default: return "Unknown";
        }
    }
};

const StoreID = {
    UNDEFINED: 0,
    GOOGLE_PLAY: 1,
    APP_STORE: 2,
    PWA: 3,
    UNIVERSAL: 10,
    toString(status) {
        switch (status) {
            case StoreID.GOOGLE_PLAY: return "Android (GooglePlay)";
            case StoreID.APP_STORE: return "iOS (AppStore)";
            case StoreID.PWA: return "PWA";
            case StoreID.UNIVERSAL: return "Universal";
            default: return "Unknown";
        }
    },
    getValues() {
        return Object.values(this).filter(v => typeof v === 'number' && parseInt(v)>0)
            .map(v => ({id: v, name: this.toString(v)}));
    }
};

const DomainState = {
    INACTIVE: 0,
    ACTIVE: 1,
};

const TrafficEntryState = {
    NEW: 0,
    ACTIVE: 1,
};

const FilterLevel = {
    UNDEFINED: 0,
    OFF: 1,
    LOW: 2,
    MID: 3,
    HIGH: 4,
    toString(filterLevel) {
        switch (filterLevel) {
            case FilterLevel.UNDEFINED: return "Undefined";
            case FilterLevel.OFF: return "Off";
            case FilterLevel.LOW: return "Low";
            case FilterLevel.MID: return "Mid";
            case FilterLevel.HIGH: return "High";
            default: return "Unknown";
        }
    },
    allowedFilterLevels() { return [FilterLevel.OFF, FilterLevel.LOW, FilterLevel.MID, FilterLevel.HIGH]; }
};

const WhitePageType = {
    GOOGLE_PLAY: 1,
    APP_STORE: 2,
    WORD_PRESS: 4,
    SAFE_PAGE: 5
};

const BackgroundJobType = {
    WHITEPAGE_GENERATOR: 1,
};

const BackgroundJobState = {
    NEW: 0,
    COMPLETED: 1,
    FAILED: 2,
    PROCESSING: 3,
};

module.exports = {
    UserGroup,
    EventNames,
    PUSH_SOUNDS,
    FBCabState,
    OrganicsState,
    GameReleaseState,
    NodeIPState,
    Token,
    DepositRequestStatus,
    APILevel,
    StoreID,
    DomainState,
    TrafficEntryState,
    FilterLevel,
    WhitePageType,
    BackgroundJobType,
    BackgroundJobState
};
import React from 'react';
import {StoreID} from '../../common/commonTypes';

const buttons = [
    { appType: StoreID.APP_STORE, name: 'iOS', icon: 'ios'},
    { appType: StoreID.GOOGLE_PLAY, name: 'Android', icon: 'android'},
    { appType: StoreID.UNIVERSAL, name: 'Universal', icon: 'universal'},
];

const MenuAppTypeItem = ({appType, name, icon, isActive, onClick}) =>
    <a className={isActive ? 'active' : ''} onClick={onClick}><div className={'icon'}
            style={{backgroundImage: `url(/icons/${icon}.png`}}/>{name}</a>

const MenuAppType = ({activeAppType, onSelect}) => {
    return <div className="horz-menu">
        <div className="horz-menu-list">
            {buttons.map(b => <MenuAppTypeItem
                appType={b.appType} name={b.name} icon={b.icon}
                    isActive={b.appType === activeAppType} onClick={onSelect.bind(null,b.appType)}/>
            )}
        </div>
    </div>
};

export default MenuAppType;
